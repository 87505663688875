import Buffer from 'buffer';

import axiosLib from 'axios';
import { render } from 'preact';

import 'moment';
import 'moment/locale/ru';
import 'moment/locale/en-gb';

import config from './config.json';
import './assets/global-styles.scss';
import './assets/global-mui-overrides.scss';

// need for package "multicoin-address-validator"
window.Buffer = Buffer.Buffer;
global.Buffer = Buffer.Buffer;

function initApp() {
  import('src/operations/authOperations')
    .then(({ autoFetchingData }) => { autoFetchingData(); });
  import('./App')
    // eslint-disable-next-line react/jsx-pascal-case
    .then((Module) => { render(<Module.default />, document.body); });
}

if (process.env.PREACT_APP_ENV === 'development') {
  config.REACT_APP_X_API_KEY = process.env.PREACT_APP_X_API_KEY;
  config.REACT_APP_API_AUTH_BASEPATH = process.env.PREACT_APP_API_AUTH_BASEPATH;
  config.REACT_APP_PRODUCTS = JSON.parse(process.env.PREACT_APP_PRODUCTS || '{}');
  config.REACT_APP_API_BACKEND_BASEPATH = process.env.PREACT_APP_API_BACKEND_BASEPATH;
  initApp();
} else {
  axiosLib.get('/config.json')
    .then((result) => {
      if (!result.data || !result.data.REACT_APP_API_BACKEND_BASEPATH) {
        throw Error('value for REACT_APP_API_BACKEND_BASEPATH not found !');
      }
      config.REACT_APP_PRODUCTS = result.data.REACT_APP_PRODUCTS;
      config.REACT_APP_API_AUTH_BASEPATH = result.data.REACT_APP_API_AUTH_BASEPATH;
      config.REACT_APP_API_BACKEND_BASEPATH = result.data.REACT_APP_API_BACKEND_BASEPATH;
      initApp();
    });
}
